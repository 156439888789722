import React, { Component } from 'react';

class Portfolio extends Component {
  render() {
    return (
      <section id="issues">
        <div className="row projects">
          <div className="twelve columns collapsed">
            <br/>
            <h2>Magazines</h2>
            <hr/>
            <br/>

            <div className="article-wrapper">
                <article className="col-12 col-12-xsmall work-item">
                <a href="/issues/issue04.pdf" className="image fit thumb" target="_blank" rel="noopener noreferrer"><img src="issues/issue04.jpg" alt="magazine" /></a>
                  <div className="art-description">
                    <div className="text-wrapper">
                      <h3>Issue 04 - Venus</h3>
                      <p>
                        Filled with Science and Fun
                        <ul>
                          <li>New Story - Brownies for Bees E-01</li>
                          <li>Learn all about Hydrogen</li>
                          <li>Let us study about Venus - our neighbour</li>
                          <li>Jokes and Puzzles</li>
                          <li>Quiz : chance to win exciting prizes.</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </article>
              </div>
              <hr/>

            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
              <div className="article-wrapper">
                <article className="col-12 col-12-xsmall work-item">
                  <a href="/issues/issue03.pdf" className="image fit thumb" target="_blank" rel="noopener noreferrer"><img src="issues/issue03.jpg" alt="magazine" /></a>
                  <div className="art-description">
                    <div className="text-wrapper">
                      <h3>Issue 03</h3>
                      <p>
                        Filled with Science and Fun
                        <ul>
                          <li>Comic Strip : Atlanta's Race E-03 - The final episode</li>
                          <li>Minature Mercury : All about the tiny planet. Collect all the cards.</li>
                          <li>Did you know about The Periodic Table?</li>
                          <li>Jokes</li>
                          <li>Quiz : chance to win exciting prizes.</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </article>
              </div>
              <hr/>

              <div className="article-wrapper">
                <article className="col-12 col-12-xsmall work-item">
                <a href="/issues/issue02.pdf" className="image fit thumb" target="_blank" rel="noopener noreferrer"><img src="issues/issue02.jpg" alt="magazine" /></a>
                  <div className="art-description">
                    <div className="text-wrapper">
                      <h3>Issue 02 - Science Special</h3>
                      <p>
                        Filled with Science and Fun
                        <ul>
                          <li>Comic Strip : Atlanta's Race E-02</li>
                          <li>James Webb Space Telescope : We will now find the mysteries of our Universe</li>
                          <li>Did you know about Radioactive Decay?</li>
                          <li>Jokes and Puzzles</li>
                          <li>Quiz : chance to win exciting prizes.</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </article>
              </div>
              <hr/>

              <div className="article-wrapper">
                <article className="col-12 col-12-xsmall work-item">
                <a href="/issues/issue01.pdf" className="image fit thumb" target="_blank" rel="noopener noreferrer"><img src="issues/issue01.jpg" alt="magazine" /></a>
                  <div className="art-description">
                    <div className="text-wrapper">
                      <h3>Issue 01 - New Year Special</h3>
                      <p>
                        Filled with Fun and Entertainment 
                        <ul>
                          <li>New Comic Strip : Atlanta's Race </li>
                          <li>Local News : Did you know about the new train station at Beaulieu?</li>
                          <li>International News : We've “touched” the sun </li>
                          <li>Jokes and Puzzles</li>
                          <li>Quiz : chance to win exciting prizes</li>
                        </ul>
                      </p>
                    </div>
                  </div>

                </article>
              </div>
            </div>
          </div>
        </div>
   </section>
    );
  }
}

export default Portfolio;
